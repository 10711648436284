<template>
  <div class="min-h-[50px]">
    <RikazGrid
      v-if="firstTwentyMaterials.length"
      name="material"
      :items="firstTwentyMaterials"
      margin="-mr-1"
    >
      <template #card="{ forKey, data }">
        <MaterialsCard
          :key="`material-grid-item-${forKey}`"
          class="transition-all duration-500 transform hover:scale-105"
          :material-props="( data as Material )"
        />
      </template>
    </RikazGrid>
    <slot name="after-first-twenty-materials" />

    <RikazGrid
      v-if="secondTwentyMaterials.length"
      name="material"
      :items="secondTwentyMaterials"
      margin="-mr-1"
    >
      <template #card="{ forKey, data }">
        <MaterialsCard
          :key="`material-grid-item-${forKey}`"
          class="transition-all duration-500 transform hover:scale-105"
          :material-props="( data as Material )"
        />
      </template>
    </RikazGrid>
    <slot name="after-second-twenty-materials" />
    <RikazGrid name="material" :items="otherMaterials" margin="-mr-1">
      <template #card="{ forKey, data }">
        <MaterialsCard
          :key="`material-grid-item-${forKey}`"
          class="transition-all duration-500 transform hover:scale-105"
          :material-props="( data as Material )"
          :wishlist="wishlist"
          :branch-id-initail="branchId"
          @toggle-wishlist="reset"
        />
      </template>
    </RikazGrid>

    <ClientOnly>
      <button
        v-if="manual && materials.length >= itemsPerPage"
        :aria-label="t('view_more', { of: t('of'), name: t('materials') })"
        class="block mx-auto"
        :disabled="loadState == 'done'"
        @click="manualFetch"
      >
        <div
          v-if="loadState == 'done'"
          class="opacity-80 classButton cursor-default"
        >
          {{ t('loaded') }}
        </div>
        <div
          v-else
          class="hover:bg-button_color classButton text-text_alt_color bg-nav_color"
        >
          {{ t('load_more') }}
        </div>
      </button>

      <MevcutInfiniteScroll
        v-else
        class="my-2"
        :has-initial-cached-materials="materials.length > 0"
        :infinite-id="infiniteId"
        @load-more="loadMore"
      />
    </ClientOnly>
  </div>
</template>

<script setup lang="ts">
import { useToast } from 'vue-toastification'
import getAllRoutes from '~/composables/AppApiRoutes'
import type { LoadState } from '~/composables/useInfiniteScroll'
import type { Material } from '~~/composables/useMenuModel'

const isScreenLoaded = ref(false)
const { cacheMaterials, getMaterials } = useCacheSession()
const innerWidth = ref(0)
const props = withDefaults(
  defineProps<{
    initialLimit?: number
    fetchRoute?: string
    fetchParams?: Record<string, any>
    withQueryParams?: boolean
    wishlist?: boolean
    manual?: boolean
    branchId?: number
    keyCache?: string
  }>(),
  {
    initialLimit: 10,
    fetchRoute: getAllRoutes().materialsRoutes.shippingRoutes.materialsByServer,
    fetchParams: () => ({ is_home_page: 1 }),
    withQueryParams: false,
    wishlist: false,
    manual: false,
    keyCache: 'materialsHomeGrid',
    branchId: undefined
  }
)

const toast = useToast()
const materials = ref<Material[]>([...getMaterials(props.keyCache)])
const loadState = ref<LoadState>()
const route = useRoute()
const { t } = useI18n()
const itemsPerPage = computed(() => {
  return isScreenHasFiveItems.value ? 25 : 24
})

const isScreenHasFiveItems = computed(() => {
  if (process.client || isScreenLoaded.value) {
    return innerWidth.value > 1189
  }
  return false
})
const totalItems = computed(() => {
  if (materials.value?.length) {
    return materials.value?.length
  }
  return 0
})
const { infiniteId, scrollFetch, loadMore, reset } = useInfiniteScroll(
  totalItems.value,
  totalItems.value ? itemsPerPage.value : props.initialLimit,
  fetchMaterials,
  appendMaterials,
  clearMaterials
)
if (!materials.value.length) {
  await manualFetch()
}

if (props.withQueryParams) {
  watch(
    () => route.query,
    () => reset()
  )
}

async function fetchMaterials(state: ScrollState) {
  let queryParams = {}
  if (props.withQueryParams) {
    queryParams = { ...route.query }
  }

  try {
    const { data, error } = await useBasicFetch<ApiResponse<[]>>(props.fetchRoute, {
      query: {
        offset: `${state.offset}`,
        limit: `${state.limit}`,
        ...queryParams,
        ...props.fetchParams
      }
    })

    if (error?.value) {
      throw error.value
    }

    return data.value
  } catch (error) {
    if (process.client) {
      toast.error(t('fetch_failed', { data: t('materials') }), {
        timeout: 1000
      })
    }

    if ((error as any)?.data?.data) {
      return (error as any).data as ApiResponse<[]>
    } else {
      return {
        data: [],
        message: t('fetch_failed', { data: t('materials') })
      } as ApiResponse<[]>
    }
  }
}

function appendMaterials(data: []) {
  materials.value = [...materials.value, ...data]
  if (data.length) {
    cacheMaterials(props.keyCache, materials.value)
  }
}

function clearMaterials() {
  materials.value = []

  loadState.value = 'load'
}

async function manualFetch() {
  loadState.value = await scrollFetch()
}

onMounted(() => {
  isScreenLoaded.value = true
  updateInnerWidth()
  window.addEventListener('resize', updateInnerWidth)
})
const updateInnerWidth = () => {
  innerWidth.value = isScreenLoaded.value ? window.innerWidth : 0
}
onUnmounted(() => {
  window.removeEventListener('resize', updateInnerWidth)
})
const firstTwentyMaterials = computed(() => {
  if (totalItems.value) {
    return materials.value.slice(
      0,
      totalItems.value > itemsPerPage.value
        ? itemsPerPage.value
        : totalItems.value
    )
  }
  return []
})
const secondTwentyMaterials = computed(() => {
  if (materials.value?.length && firstTwentyMaterials.value.length) {
    return materials.value.slice(
      itemsPerPage.value,
      totalItems.value > 2 * itemsPerPage.value
        ? 2 * itemsPerPage.value
        : totalItems.value
    )
  }
  return []
})

const otherMaterials = computed(() => {
  if (materials.value?.length) {
    return materials.value.slice(2 * itemsPerPage.value, totalItems.value)
  }
  return []
})
</script>
<style>
.classButton {
  @apply border border-button_color mx-auto  rounded-lg px-4 pt-2 pb-1 my-2;
}
</style>
